import type { NavigateFn } from '@gatsbyjs/reach-router';
import type {
  FeaturesInfoQuery,
  HomepageTemplateQuery,
  MarkdownRemarkFrontmatter,
} from '../../../generated/types';
import type { ConfigItem } from './types';

export function filterContentByLocale(
  locale: string,
  data: FeaturesInfoQuery | HomepageTemplateQuery,
): MarkdownRemarkFrontmatter | undefined | null {
  switch (locale) {
    case 'es':
      return data.spanish?.frontmatter;
    case 'lt':
      return data.lithuanian?.frontmatter;
    default:
      return data.english?.frontmatter;
  }
}

export function storeLocale(chosenLanguage: string): void {
  if (chosenLanguage === 'en') {
    localStorage.removeItem('locale');
    return;
  }
  localStorage.setItem('locale', JSON.stringify(chosenLanguage));
}

export function findByLocalName(config: Array<ConfigItem>, filter: string): ConfigItem | undefined {
  return config.find((item: ConfigItem) => item.localName === filter);
}

export function findByCode(config: Array<ConfigItem>, filter: string): ConfigItem | undefined {
  return config.find((item: ConfigItem) => item.code === filter);
}

export function getLocalizedPath(path: string): string | null {
  const storedLocale: string = JSON.parse(localStorage.getItem('locale') as string);
  if (!storedLocale) {
    return null;
  }
  if (path.split('/').includes(storedLocale)) {
    return null;
  }
  return `/${storedLocale}${path}`;
}

export function navigateToLocalizedPath(pathname: string, navigatorFunc: NavigateFn): void {
  const newPath = getLocalizedPath(pathname);
  if (typeof newPath === 'string') {
    void navigatorFunc(newPath);
  }
}
