import { useEffect } from 'react';
import { css } from '@emotion/react';
import { navigate, useLocation } from '@gatsbyjs/reach-router';
import { Button, Container, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AppLogo } from '@noah-labs/fe-shared-ui-components';
import { graphql } from 'gatsby';
import type { PageProps } from 'gatsby';
import type { NotFoundPageQuery } from '../../generated/types';
import { MarkdownTypography } from '../components';
import { navigateToLocalizedPath } from '../components/i18n/utils';
import { BaseWrapper } from '../components/layout/BaseWrapper';
import { Footer } from '../components/layout/Footer';
import { HeaderContainer } from '../components/navbar/HeaderContainer';

type PpNotFoundPage = PageProps<NotFoundPageQuery>;

function NotFoundPage({ data: { content } }: PpNotFoundPage): React.ReactElement {
  const theme = useTheme();
  const location = useLocation();
  useEffect(() => {
    navigateToLocalizedPath(location.pathname, navigate);
  }, [location.pathname]);

  const styles = {
    image: css`
      width: 50%;
      ${theme.breakpoints.down('md')} {
        width: 100%;
      }
    `,
  };

  return (
    <BaseWrapper
      FooterSlot={<Footer legalCompanyName={content?.frontmatter?.legalCompanyName} />}
      HeaderSlot={
        <HeaderContainer isScrolled backgroundColor="transparent">
          <Container disableGutters component="nav" maxWidth="lg" sx={{ padding: 2 }}>
            <AppLogo />
          </Container>
        </HeaderContainer>
      }
    >
      <Stack alignItems="center" spacing={4} sx={{ paddingTop: 12 }}>
        <Typography component="h1" variant="bodyHeadingM">
          {content?.frontmatter?.notFound?.notFoundTitle}
        </Typography>
        <MarkdownTypography component="p" variant="paragraphBodyS">
          {content?.frontmatter?.notFound?.notFoundCopy}
        </MarkdownTypography>
        <Button color="primary" component="a" href="https://noah.com" variant="contained">
          {content?.frontmatter?.notFound?.notFoundButton}
        </Button>
        {content?.frontmatter?.notFound?.notFoundImage?.src && (
          <img
            alt={content?.frontmatter?.notFound?.notFoundImage.alt || 'Not Found'}
            css={styles.image}
            src={content?.frontmatter.notFound.notFoundImage.src}
          />
        )}
      </Stack>
    </BaseWrapper>
  );
}
export const query = graphql`
  query NotFoundPage($locale: String!) {
    content: markdownRemark(frontmatter: { layout: { eq: "info" }, locale: { eq: $locale } }) {
      frontmatter {
        notFound {
          notFoundImage {
            src
            alt
          }
          notFoundTitle
          notFoundCopy
          notFoundButton
        }
        legalCompanyName
        notFoundSeo {
          title
          description
          image
        }
      }
    }
  }
`;

export default NotFoundPage;
